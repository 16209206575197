
const ProductsReducer = (state, action) => {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                isLoading: true,
            };

            case "SET_API_DATA":
                const featuredProduct = action.payload.filter((index)=>{
                    return index.featured === true;
                })

                return{
                    ...state,
                    isLoading: false,
                    products: action.payload,
                    featureProducts: featuredProduct,
                }

            case "MY_API_ERROR":
                return{
                    ...state,
                    isLoading: false,
                    isError: true,
                }

                case "SET_SINGLE_LOADING":
                    return {
                        ...state,
                        isLoading: true,
                    };

                    case "SINGLE_PRODUCT":
                        return {
                            ...state,
                            isLoading: false,
                            singleProduct: action.payload,
                        };


            case "SET_SINGLE_ERROR":
                return{
                    ...state,
                    isSingleLoading: false,
                    isError: true,
                }


                default:
                    return{
                        ...state,
                    }

    }
   return state

}

export default ProductsReducer