import { Menu, ShoppingCart, XCircle } from 'lucide-react';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useCartContext } from '../Context/CartContext';


function Navbar() {
  const [menuopened, setMenuopened] = useState(false)
  const {total_item} = useCartContext()
 
  const Nav = styled.nav`
  .navbar-lists {
    display: flex;
    gap: 4.8rem;
    align-items: center;
    margin-right:5rem;
  
    .navbar-link {
      &:link,
      &:visited {
        display: inline-block;
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.btn};
        transition: color 0.3s linear;
      }
  
      &:hover,
      &:active {
        color: ${({ theme }) => theme.colors.helper};
      }
    }
  }
  
  .mobile-navbar-btn {
    display: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    margin-right: 1.2rem;
  }
  
  .mobile-nav-icon[name="close-outline"] {
    display: none;
  }
  
  .close-outline {
    display: none;
  }
  
  .cart-trolley--link {
    position: relative;

    .cart-trolley s {
      position: relative;
      font-size: 3.2rem;
    }
  
    .cart-total--item {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      background-color: #000;
      color: #fff;
      border-radius: 50%;
      display: grid;
      place-items: center;
      top: -20%;
      left: 70%;
      background-color: ${({ theme }) => theme.colors.bg};
      font-size: 1.1rem;
      border: #f7f1ea 2px solid;
    }
    
  }
  
  .user-login--name {
    text-transform: capitalize;
  }
  
  .user-logout,
  .user-login {
    font-size: 1.4rem;
    padding: 0.8rem 1.4rem;
  }
  
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .mobile-navbar-btn {
      display: inline-block;
      z-index: 9999;
      border: ${({ theme }) => theme.colors.btn};
      
  
      .mobile-nav-icon {
        font-size: 4.2rem;
        color: ${({ theme }) => theme.colors.btn};
      }
    }
  
    .active .mobile-nav-icon {
      display: none;
      font-size: 4.2rem;
      position: absolute;
      top: 30%;
      right: 10%;
      color: ${({ theme }) => theme.colors.btn};
      z-index: 9999;
    }
  
    .active .close-outline {
      display: inline-block;
    }
  
    .navbar-lists {
      width: 100vw;
      height: 100vh;
    
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      
  
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      visibility: hidden;
      opacity: 0;
      transform: translateX(100%);
      /* transform-origin: top; */
      transition: all 3s linear;
    }
  
    .active .navbar-lists {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      z-index: 999;
      transform-origin: right;
      transition: all 3s linear;
  
      .navbar-link {
        font-size: 4.2rem;
      }
    }
    .cart-trolley--link {
      position: relative;
  
      .cart-trolley {
        position: relative;
        font-size: 5.2rem;
      }
  
      .cart-total--item {
        width: 4.2rem;
        height: 4.2rem;
        font-size: 2rem;
      }
    }
  
    .user-logout,
    .user-login {
      font-size: 2.2rem;
      padding: 0.8rem 1.4rem;
    }
  }
  `;

  return (
    <Nav>
      <div className={menuopened? 'navbar active': 'navbar'}>
        <ul className='navbar-lists'>
          <li>
            <NavLink to='/' className='navbar-link' onClick={()=>setMenuopened(false)} >
              Home
            </NavLink>
          </li>

          <li>
            <NavLink to='/about' className='navbar-link' onClick={()=>setMenuopened(false)} >
              About
            </NavLink>
          </li>

          <li>
            <NavLink to='/Products' className='navbar-link' onClick={()=>setMenuopened(false)} >
              Products
            </NavLink>
          </li>

          <li>
            <NavLink to='/contact' className='navbar-link' onClick={()=>setMenuopened(false)} >
              Contact
            </NavLink>
          </li>

          <li>
            <NavLink to='/cart' className='navbar-link cart-trolley--link'>
                <ShoppingCart className='cart-trolley'/>
                <span className='cart-total--item' style={{color: "white"}}>{total_item}</span>
            </NavLink>
          </li>
        </ul>

        {/* responsiveness */}
        <div className='mobile-navbar-btn'>
          <Menu name='menu-outline' className='mobile-nav-icon' onClick={()=>setMenuopened(true)} width={30} height={30}/>
          <XCircle name='close-outline' className='mobile-nav-icon close-outline' onClick={()=>setMenuopened(false)} width={50} height={50}/>
        </div>
      </div>
    </Nav>    
  )
}  


export default Navbar