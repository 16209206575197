import { TruckIcon, ShieldCheck, CircleDollarSign, Shield } from 'lucide-react';
import React from 'react'
import styled from 'styled-components'

const Services = () => {
  return (
    <Wrapper>
            <h3 className='text'>What Services we offer</h3>
        <div className='container'>
            <div className='grid grid-three-column'>
                <div className='services-1'>
                    <div>
                        <TruckIcon className='icon'/>
                        <h3>Fast Delivery</h3>
                    </div>
                </div>

                <div className='services-2'>
                    <div className='services-colum-2'>
                    <ShieldCheck  width={13} className='icon'/>
                    <h3>Secure</h3>
                    </div>

                    <div className='services-colum-2'>
                    <CircleDollarSign className='icon'/>
                    <h3>Money back Guaranteed</h3>
                    </div>
                </div>

                <div className='services-3'>
                    <div>
                        <Shield className="icon"/> 
                        <h3>super secure payment</h3>
                    </div>
                </div>

            </div>
        </div>
    </Wrapper>
    
  )
}
const Wrapper = styled.section`
  padding: 9rem 0;

  .grid {
    gap: 4.8rem;
  }

  .services-1,
  .services-2,
  .services-3 {
    width: auto;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: ${({ theme }) => theme.colors.bg} ;
    text-align: center;
    border-radius: 3rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
  }

  .services-2 {
    gap: 4rem;
    background-color: transparent;
    box-shadow: none;
    // box-shadow: ${({ theme }) => theme.colors.shadowSupport}

    .services-colum-2 {
      background: ${({ theme }) => theme.colors.bg};
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      box-shadow: ${({ theme }) => theme.colors.shadowSupport}

      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  h3 {
    margin-top: 1.4rem;
    font-size: 2rem;
    color: #f7f1ea;
  }

  .icon {
    /* font-size: rem; */
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: #fff;
    color: #5138ee;
    margin-right:1.3rem;
  }
  .text{
    margin-left: 30rem;
    margin-bottom: 9rem;
    font-size: 5rem;
    font-weight: bold;
    color:#000;
  }
`;
export default Services