import React, { useState } from "react";
import FormatPrice from "../Helpers/FormatPrice";
import CartAmount from "./CartAmount";
import { Trash2 } from "lucide-react";
import { useCartContext } from "../Context/CartContext";

const CartItem = ({ id, name, image, colo, price, amount}) => {
  const {removeCart, setDecrease, setIncrease} = useCartContext()
  // const [Amount, setAmount] = useState(1);

  // const setDecrease = () => {
  //   Amount > 1 ?setAmount(Amount - 1) : setAmount(1)
  // };
  // const setIncrease = () => {
  //   Amount < stock ? setAmount(Amount + 1) : setAmount(stock)
  // };

  return (
    <div className="cart_heading grid grid-five-column">
      <div className="cart-image--name">
        <div>
          <figure>
            <img src={image} alt={id} />
          </figure>
        </div>
        <div>
          <p>{name}</p>
          <div className="color-div">
            <p>color:</p>
            <div
              className="color-style"
              style={{ backgroundColor: colo, color: colo }}
            ></div>
          </div>
        </div>
      </div>
      {/* price   */}
      <div className="cart-hide">
        <p>
          <FormatPrice price={price} />
        </p>
      </div>

      {/* Quantity  */}
      <CartAmount
        amount={amount}
        setDecrease={() =>setDecrease(id)}
        setIncrease={() =>setIncrease(id)}
      />

      {/* Subtotal */}
      <div className="cart-hide">
        <p>
          <FormatPrice price={price * amount} />
        </p>
      </div>

      <div>
      <Trash2 className="remove_icon" onClick={()=>removeCart(id)}/>
      </div>
  
    </div>

  );
};

export default CartItem;
