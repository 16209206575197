
const CarReducer = (state, action) => {
    if(action.type === "ADD_TO_CART"){
        let {id, colo, amount, product} = action.payload

        let existingProduct = state.cart.find((item)=>item.id === id+colo)

        if (existingProduct) {
            let updatedProduct = state.cart.map((curElem) => {
              if (curElem.id == id + colo) {
                let newAmount = curElem.amount + amount;
      
                if (newAmount >= curElem.max) {
                  newAmount = curElem.max;
                }
                return {
                  ...curElem,
                  amount: newAmount,
                };
              } else {
                return curElem;
              }
            });
            return {
              ...state,
              cart: updatedProduct,
            };
          } else {
            let cartProduct = {
              id: id + colo,
              name: product.name,
              colo,
              amount,
              image: product.image[0].url,
              price: product.price,
              max: product.stock,
            };
      
            return {
              ...state,
              cart: [...state.cart, cartProduct],
            };
          }
        }
    

    if(action.type === "REMOVE_CART"){
        let updatedCart = state.cart.filter((item)=> item.id !== action.payload)
        
        return {
         ...state,
         cart:updatedCart,
    }
    }

    if(action.type === "CLEAR_CART"){
        return{
            ...state,
            cart: [],
        }
    }

    // decreasing the quantity 

    if(action.type === "SET_DECREASE"){
      let decreaseAmount = state.cart.map((item=>{
        if(item.id === action.payload){
          let decrementAmount = item.amount - 1
          if(decrementAmount <=0){
            decrementAmount = 1
          }

          return{
            ...item,
            amount: decrementAmount,
          }
        }
        else{
          return{
            ...item,
          }
        }
      }))
      return {
        ...state,
        cart: decreaseAmount
      }
    }

    // Increaseing quantity 

    if(action.type === "SET_INCREASE"){
      let incrementAmount = state.cart.map((item)=>{
        if(item.id === action.payload){
          let increaseAmount = item.amount + 1
          if(increaseAmount > item.max){
            increaseAmount = item.max
          }

          return{
            ...item,
            amount: increaseAmount,
          }
       }else{
        return {...item}
       }
      })
      return{
        ...state,
        cart: incrementAmount,
      }
    }

    if(action.type === "CART_TOTAL_ITEM"){
      let totalval = state.cart.reduce((initialVal, item)=>{
        let {amount} = item
        initialVal = initialVal + amount;
        return initialVal 
      }, 0)
      return{
        ...state,
        total_item: totalval,
      }
      
    }

    if(action.type === "CART_TOTAL_PRICE"){
      let totalPrice = state.cart.reduce((initialPrice, item)=>{
        let {price, amount} = item
        initialPrice  = initialPrice + price * amount

        return initialPrice
      }, 0)

      return{
        ...state,
        total_price: totalPrice,
      }
    }
  
      return state
}

export default CarReducer