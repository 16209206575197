import React from "react";
import { useProductContext } from "../Context/ProductContext";
import styled from "styled-components";
import Product from "./Product";

const FeatureProducts = () => {
  const { isLoading, featureProducts } = useProductContext();
  
  if(isLoading) {
    return <h1 className="common-heading">Loading...</h1>
  }
  
  return (
    
    <Wrapper className="section">
      <div className="container">
        <div className="intro-data">Check Now!</div>
        <div className="common-heading">Our Feature Services</div>
        <div className="grid grid-three-column">
          {featureProducts.map((curElem) => {
            return <Product key={curElem.id} {...curElem} />;
            
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;
  background-color: #ffe;

  .container {
    max-width: 120rem;
  }

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: white;
      transition: all 0.2s linear;
      cursor: pointer;
    }

    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 25rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: #000;
      color: white;
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
      border: .1rem solid white;
    }
  }

  .card {
    background-color: #ffe;
    border-radius: 1rem;
    transition: all ease-in-out;

    .card-data {
      padding: 0 2rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card:hover {
      transform: scaleY(1);
    }

    h3 {
      color: ${({ theme }) => theme.colors.text};
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.helper};
    }

    .btn {
      margin: 2rem auto;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
      .load{
        margin-left:3rem;
      }
    }
  }
`;

export default FeatureProducts;
