import React, { useContext } from "react";
import Hero from "./component/Hero";
import styled from "styled-components";
import { useProductContext } from "./Context/ProductContext";

const About = () => {
  const { myName } = useProductContext();

  const data = {
    names: "Khubaib's E-Commerce",
  };

  return (
    <Wrapper>
  {myName}
    <Hero myData={data} />{""}
    
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: #ffe;
`;

export default About;
