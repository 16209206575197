import React from 'react'
import styled from 'styled-components'
import FormatPrice from '../Helpers/FormatPrice'
import { NavLink } from 'react-router-dom'
import {Button} from '../styles/Button'


const LIstView = ({products}) => {
  return (
    <Wrapper>
      <div className='section'>
        <div className='grid container'>
          {
            products.map((curElem, index)=>{
              const {id, name, image, price, description} = curElem
              return <div className='card grid grid-two-column' key={index}>
              <figure>
                <img src={image}/>
              </figure>

              <div>
                <h3>{name}</h3>
                <p>{description.slice(0, 77)}</p>
                <p><FormatPrice price={price}/></p>
              <NavLink to={`/singleProduct/${id}`}>
                <Button className='buton'>Read More</Button>
              </NavLink>
              </div>

              </div>
            })
          }
        </div>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.section`
  padding: 9rem 0;
  
  .container {
  max-width: 120rem;
  border-radius:1rem;
  }
  
  .grid {
    gap: 3.2rem;
  }
  
  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 20rem;
      transition: all 0.2s linear;
      border-radius:1rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .card {
    background-color:#ffe;
    border-radius: 1rem;
    border: 0.1rem solid rgb(170 170 170 / 40%);
    
    .card-data {
      padding: 0 2rem;
    }
    
    h3 {
      margin: 2rem ;
      font-weight: 300;
      font-size: 2.4rem;
      text-transform: capitalize;

    }
    
    .btn {
      margin: 2rem 0;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(98 84 243);
      
      &:hover {
        background-color: rgb(98 84 243);
      }
      
      &:hover a {
        color: #ffe;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }
    
    .btn-main .btn:hover {
      color: #ffe;
    }
  }
  @media (max-width: 568px) {
    h3{
      margin-left:1rem;
    }
    .card {
      background-color:#ffe;
      border-radius: 1rem;
      border: 0.1rem solid rgb(170 170 170 / 40%);
      
      .card-data {
        margin-left: 1rem;
      }
      .btn{
        margin-left: 1rem;
        border-radius: 1rem;

      }
      img {
        max-width: 90%;
        margin-top: 1.5rem;
        height: 20rem;
        transition: all 0.2s linear;
        border-radius:1rem;
        margin-bottom: 1.5rem;
        width: 80%;
      }
      p{
        margin-left: 2rem;
      }
      .buton{
        border-radius: 2rem;
        width:20rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
  }
  `;
  
  export default LIstView