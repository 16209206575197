import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Navbar from './Navbar'
import { ShoppingBag } from "lucide-react";

function Header() {
  return (
    <MainHeader>
      <NavLink to="/" style={{display:'flex'}}>
        <ShoppingBag width={'100px'} height={'50px'} style={{color:'#f7f1ea'}} />
        <h3 style={{marginTop:'2rem', marginLeft:'.5rem', color:'#f7f1ea',}}>Khubaib's Store</h3>
      </NavLink>
      <Navbar />
    </MainHeader>
  );
}

const MainHeader = styled.header`
  padding: 0 4.8;
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .logo {
    height: 5rem;
  }
`;

export default Header;
