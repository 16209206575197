import React from "react";
import { useFilterContext } from "../Context/FilterContext";
import GridView from "./GridView";
import LIstView from "./LIstView";
import { useProductContext } from "../Context/ProductContext";

const ProductList = () => {
  const { filter_products, grid_view } = useFilterContext();
  const {isLoading} = useProductContext()

  if (isLoading) {
    return <h2>Loading...</h2>
  }
  if (grid_view === true) {
    return <GridView products={filter_products} />;
  }

  if (grid_view === false) {
    return <LIstView products={filter_products} />;
  }

};

export default ProductList;