import React from 'react'
import styled from 'styled-components';
import {BsFillGridFill, BsList} from 'react-icons/bs'
import { useFilterContext } from '../Context/FilterContext';

const Sort = () => {
  const {grid_view, setGridView, setListView, filter_products, sorting} =useFilterContext()

 
  return (
    <Wrapper className='sort-section'>
      {/* 1st col  */}
      <div className='sorting-list--grid'>

       
        <button className={grid_view ? "active sort-btn" : "sort-btn"}
        onClick={setGridView}
        >
          <BsFillGridFill  className='icon'/>
        </button>
        

        
        <button className={!grid_view ? "active sort-btn" : "sort-btn"}
        onClick={setListView}
        >
          <BsList className='icon'/>
        </button>
        
      </div>
      
        <div className='product-data'>
          <p>{`${filter_products.length} Products Available`}</p>
        </div>
        <div className='sort-selection'>
          <form action='#'>
            <label htmlFor='sort'></label>
            <select name='sort' id='sort' className='sort-selection--style' onClick={sorting}>
              <option value="Lowest">Price(lowest)</option>
              <option value="#" disabled></option>
              <option value="Highest">Price (Highest)</option>
              <option value="#" disabled></option>
              <option value="a-z">Price (a-z)</option>
              <option value="#" disabled></option>
              <option value="z-a">Price (z-a)</option>
            </select>
          </form>
        </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;

  .sorting-list--grid {
    display: flex;
    gap: 2rem;

    .sort-btn {
      padding: 0.8rem 1rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius:5px;

    }

    .icon {
      font-size: 1.6rem;
    }
    .active {
      background-color: #000;
      color: #fff;
    }
  }

  .sort-selection .sort-selection--style {
    padding: 0.5rem;
    cursor: pointer;
    background-color: #ffe;
    border: 1px solid black;
    border-radius:1rem;

    .sort-select--option {
      padding: 0.5rem 0;
      cursor: pointer;
      height: 2rem;
      padding: 10px;
      background-color: #ffe;
      border: 1px solid black;
      border-radius:1rem;
     box-shadow: 4px 15px 135px -50px rgba(0,0,0,0.93);
-webkit-box-shadow: 4px 15px 135px -50px rgba(0,0,0,0.93);
-moz-box-shadow: 4px 15px 135px -50px rgba(0,0,0,0.93);

    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .sorting-list--grid{
      gap: 10px;
      margin: 0 2rem
    }
  }
`;
export default Sort