import { createContext, useContext, useReducer, useEffect } from "react";
import reducer from "../Reducer/CarReducer"

const CartContext = createContext();
const getLocalCartData= ()=>{
  let newCartData = localStorage.getItem("cart")

  if(newCartData == []){
    return []
  }else
  return JSON.parse(newCartData)
}

const initialState = {
  // cart: [],
  cart: getLocalCartData(),
  total_item: "",
  total_price: "",
  shipping_fee: 5000,
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToCart = (id, colo, amount, product) => {
    dispatch({ type: "ADD_TO_CART", payload: { id, colo, amount, product } });
  };

  const removeCart = (id) => {
    dispatch({ type: "REMOVE_CART", payload:id })
  }

  // increment and decrement 

  const setDecrease = (id)=>{
    dispatch({type: "SET_DECREASE", payload:id})
  }

  const setIncrease = (id)=>{
    dispatch({type: "SET_INCREASE", payload:id})
  }

  // to add the data in local storage
  // get vs set 

  useEffect(()=>{
    dispatch({type:"CART_TOTAL_ITEM"})
    dispatch({type:"CART_TOTAL_PRICE"})
    localStorage.setItem("cart", JSON.stringify(state.cart))
  }, [state.cart])

  // to clear Cart 

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART"})
  }
  
  return (
   <CartContext.Provider
      value={{
        ...state,
        addToCart,
        removeCart,
        clearCart,
        setDecrease,
        setIncrease,
      }}>
      {children}
    </CartContext.Provider>
  )
  
};

const useCartContext = () => {
  return useContext(CartContext);
};

export { CartProvider, useCartContext };