import { Minus, Plus } from 'lucide-react'
import React from 'react'

const CartAmount = ({amount, setDecrease, setIncrease}) => {
  return (
    <div className='cart-button'>
        <div className='amount-toggle'>
            <button onClick={()=> setDecrease()} style={{backgroundColor: 'transparent'}}><Minus /></button>
            <div className='amount-style' style={{color:"black"}}>{amount}</div>
            <button onClick={()=> setIncrease()} style={{backgroundColor:'transparent'}}><Plus /></button>
        </div>
    </div>
  )
}

export default CartAmount