import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../Reducer/Reducer";

const AppContext = createContext();

// API
const API = "https://api.pujakaitem.com/api/products";
// const Second_API = "https://api.pujakaitem.com/api/products?id="

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProducts: [],
  isSingleLoading: false,
  singleProduct:{},
  isseen: false,
};

const AppProvider = ({ children }) => {
  // useReducer Hook
  const [state, dispatch] = useReducer(reducer, initialState);

  // calling API
  const getProducts = async (url) => {

    dispatch({type:"SET_LOADING"})

    try {
        const res = await axios.get(url);
        const products = await res.data;
        dispatch({type:"SET_API_DATA", payload: products});
    } catch (error) {
     dispatch({type:"MY_API_ERROR"})   
    }
  };

  // my second API call 
  const getSingleProduct = async (url)=>{
    dispatch({type:'SET_SINGLE_LOADING'})
    try {
      const res = await axios.get(url)
      const singleProduct = await res.data; 
      dispatch({type: "SINGLE_PRODUCT", payload: singleProduct})
    } catch (error) {
      dispatch({type:"SET_SINGLE_ERROR"}) 
    }
  }

  useEffect(() => {
    getProducts(API);
  }, []);

  // context
  return (
    <AppContext.Provider value={{ ...state, getSingleProduct }}>{children}</AppContext.Provider>
  );
};

// custom hooks
const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };
