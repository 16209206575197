import React from 'react'
import styled from 'styled-components';
import { useFilterContext } from '../Context/FilterContext';
import { useProductContext } from '../Context/ProductContext';
import FormatPrice from '../Helpers/FormatPrice';
import { Button } from '../styles/Button'

const FilterSection = () => {
  const {filters:{text}, updateFilterValue, all_products,
  clearFIlters
} = useFilterContext()
  
  
  // to get unique data 
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    return (newVal = ["all", ...new Set(newVal)]);
  };

  
  // WE need unique data 
  const categoryData = getUniqueData(all_products, "category")
  
  // console.log(categoryData, companyData);
 
  
  return (
    <Wrapper>
      <div className='filter-search'>
        <form onSubmit={(e)=>e.preventDefault()}>
          <input type='text' name='text' value={text} onChange={updateFilterValue} placeholder='Search'/>
        </form>
      </div>

      <div className="filter-category">
        <h3>Category</h3>
        <p>Click on All to see the products</p>
        <div>
          {
            categoryData.map((curElem, index)=>{
              return <button
              key={index}
              type="button"
              name='category'
              value={curElem}
              onClick={updateFilterValue}
              >
               {curElem}           
              </button>
            })
          }

        </div>

          
          
        <Button className="btn" onClick={clearFIlters}>
          Clear Filters
        </Button>
      

      

       
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  h3 {
    padding: 2rem 0;
    font-size: bolder;
    font-size: 3rem;
  }

  .filter-search {
    input {
      padding: 0.6rem 1rem;
      width: 80%;
      border-radius: 3rem;
    }
  }

  .filter-category {
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.4rem;

      button {
        border: none;
        background-color: #ffe;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }

      .active {
        border-bottom: 1px solid #000;
        color: red;
      }
    }
  }

  .filter-company--select {
    padding: 0.3rem 1.2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
    background-color: #ffe;
  }

  .filter-color-style {
    display: flex;
    justify-content: center;
  }

  .color-all--style {
    background-color: transparent;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  .filter_price {
    input {
      margin: 0.5rem 0 1rem 0;
      padding: 0;
      box-shadow: none;
      cursor: pointer;
    }
  }

  .filter-shipping {
    display: flex;
    align-items: center;
    gap: 1rem;

  }

  .btn{
    border-radius: 2rem;
    width: 18rem;
    margin-top: 3rem

  }
  p{
    margin-bottom: 2rem;
    font-weight: bold;
  }

  
`;
export default FilterSection