import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import { Button } from '../styles/Button'

const Hero = ({ myData }) => {
    const { names } = myData;

  return (
        <Wrapper>
            <div className='container'> 
                <div className='grid grid-two-column'>
                    <div className='hero-section-data'>
                        <p className='intro-data'>Welcome to</p>

                        <h1>{names}</h1>

                        <p>Step into a world of style at our online store. Unleash your fashion-forward self with our diverse and curated collections. From timeless classics to cutting-edge trends, we offer a seamless shopping experience. Explore quality fabrics, affordable prices, and an array of sizes, ensuring everyone finds their perfect fit. Elevate your wardrobe effortlessly and stay ahead in the fashion game with our latest arrivals. Welcome to a destination where style meets convenience.</p>
                        <NavLink to='/products'>
                            <Button>Shop Now</Button>
                        </NavLink>
                    </div>

                    {/* our homepage image */}
                    <div className='hero-section-image'>
                        <figure>
                            <img src='images/hero.jpg' className='img-style'/>
                        </figure>
                    </div>

                </div>
            </div>

        </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 12rem 0;

  img {
    min-width: 10rem;
    height: 10rem;
  }

  .hero-section-data {
    p {
      margin: 2rem 0;
    }

    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }

    .intro-data {
      margin-bottom: 0;
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:3rem;
  }
  figure {
    position: relative;

    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: #000;
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
      border-radius:30px;
    }
  }
  .img-style {
    width: 100%;
    height: auto;
    border-radius:3rem;

    
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }

    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      /* bottom: 10%; */
      background-color: rgba(81, 56, 238, 0.4);
    }
  }
`;

export default Hero