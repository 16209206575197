import React from "react";
import Hero from "./component/Hero";
import Services from "./component/Services";
import Trusted from "./component/Trusted";
import styled from "styled-components";
import FeatureProducts from "./component/FeatureProducts";

const Home = () => {
  const data = {
    names: "Khubaib's Store",
  };

  return (
    <Wrapper>
      <Hero myData={data} />
      <FeatureProducts />
      <Services />
      <Trusted />
    </Wrapper>
  );
};

const Wrapper = styled.section`
background-color: #ffe;

`
export default Home;
