import styled from "styled-components";
import { Button } from "./styles/Button";

const Contact = () => {
  const Wrapper = styled.section`
    padding: 9rem 0 5rem 0;
    text-align: center;
    background-color: #ffe;

    .container {
      margin-top: 6rem;
     

      .contact-form {
        max-width: 50rem;
        margin: auto;

        .contact-inputs {
          display: flex;
          flex-direction: column;
          gap: 3rem;

          input[type="submit"] {
            cursor: pointer;
            transition: all 0.2s;

            // &:hover {
            //   background-color: ${({ theme }) => theme.colors.white};
            //   border: 1px solid ${({ theme }) => theme.colors.btn};
            //   color: ${({ theme }) => theme.colors.btn};
            //   transform: scale(0.9);
            }
          }
        }
      }
    }
    .button{
      background-color: #0c133b;
      color: ${({ theme }) => theme.colors.btn}}
    }
  `;

  return <Wrapper>
    <h1 className="common-heading">Contact Page</h1>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28974.43922824665!2d67.00941860675812!3d24.802133217713084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33d099c12586f%3A0x8f468f64498f32f7!2sDolmen%20Mall%20Clifton!5e0!3m2!1sen!2s!4v1702484572591!5m2!1sen!2s" width="100%" height="400" style={{border:0}} allowfullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

    <div className="container">
      <div className="contact-form">
        <form action="https://formspree.io/f/xoqogonb" method="POST" className="contact-inputs">
          <input type="text" placeholder="Username" name="username" required autoComplete="off"/>
          <input type="email" placeholder="Email" name="Email" required autoComplete="off"/>
          <textarea placeholder="Enter your Message" cols={30} rows={10} name="message" required autoComplete="off"></textarea>

          <input type="submit" value="send" className="button"/>
  
        </form>
      </div>
    </div>
  </Wrapper>;
};

export default Contact;
